import { PureComponent, Fragment } from "react";
import "../sass/_global.scss";
import "./App.scss";
import "./MenuPanel.scss";
import "./SharePanel.scss";
import "./FiltersPanel.scss";
import "./OnboardingPanel.scss";
import PropTypes from "prop-types";
import { Router, Route } from "react-router-dom";
import history from "@/skeleton/history.js";
import Loading from "@/common/Loading.jsx";
import {
	pingServer,
	loadUser,
	updateUserLanguage,
	updateUserProgramme,
	removeUserProgramme,
	updateUserHomeOrganisation,
	removeUserHomeOrganisation,
	updateUserHostOrganisation,
	removeUserHostOrganisation,
	updateUserSubscribedNotificationCategories,
	updateUserHomeFaculty,
	removeUserHomeFaculty,
	updateUserEctsCreditsCompleted,
	removeUserEctsCreditsCompleted,
	updateUserStudyLevel,
	removeUserStudyLevel,
	updateUserStudyField,
	removeUserStudyField,
	updateUserLanguageSkills,
	updateUserPreferredHostFaculties,
	moveUserToNextMobilityPhase,
	loadLanguages,
	loadJourneyMenu,
	loadStream,
	loadNextStreamNodes,
	loadMenus,
	loadTerms,
	handleError,
	postPushSubscription,
	trackError,
	isOnboardingCompleted,
	setOnboardingCompleted
} from "@/skeleton/DataAccess.js";
import { DEFAULT_LANGUAGE, RUNS_THROUGH_REACT_NATIVE_APP } from "@/config.js";
import axios from "axios";
import { logoutFromDrupal } from "@/common/user.js";
import AppHeader from "@/skeleton/AppHeader.jsx";
import JourneyChecklist from "@/common/JourneyChecklist.jsx";
import JourneyMenu from "@/common/JourneyMenu.jsx";
import Notifications from "@/common/Notifications.jsx";
import Applications from "@/common/Applications.jsx";
import JourneyNavigator from "@/skeleton/JourneyNavigator.jsx";
import Stream from "@/common/Stream.jsx";
import Map from "@/common/Map.jsx";
import LocaleSwitcher from "@/common/LocaleSwitcher.jsx";
import NodeLoader from "@/nodes/NodeLoader.jsx";
import NotificationLoader from "@/skeleton/NotificationLoader.jsx";
import LoginPanel from "@/skeleton/LoginPanel.jsx";
import ProfilePanel from "@/skeleton/ProfilePanel.jsx";
import ShareOptions from "@/skeleton/ShareOptions.jsx";
import Filters from "@/skeleton/Filters.jsx";
import Menu from "@/common/Menu.jsx";
import Onboarding from "@/common/Onboarding.jsx";
import SlidingPanel from "@/common/SlidingPanel.jsx";
import { decodeQuery, getClickedAnchor, isExternalLink, tMap, filterObject } from "@/common/utils.jsx";
import {
	subscribe as subscribeToPushNotifications,
	unsubscribe as unsubscribeFromPushNotifications
} from "@/skeleton/push-notifications.js";
import { setTitle } from "@/common/DocumentTitle.js";
import Polyglot from "node-polyglot";
import Accommodation, { accommodationStaticRoutes, isAccommodationUrl } from "@/other/accommodation/Accommodation.jsx";

const pingServerDelay = 5000;

const knownStaticRoutes = [
	"/",
	"/about",
	"/applications",
	"/checklist",
	"/filters",
	"/filters/city",
	"/filters/content",
	"/filters/tags",
	"/journey",
	"/login",
	"/notifications",
	"/profile",
	"/profile/academic",
	"/profile/academic/ects",
	"/profile/academic/faculty",
	"/profile/academic/language-skills",
	"/profile/academic/study-field",
	"/profile/academic/study-level",
	"/profile/application/choices",
	"/profile/application/form",
	"/profile/application/form/resume",
	"/profile/application/form/view",
	"/profile/application/info",
	"/profile/application/period",
	"/profile/application/review",
	"/profile/avatar",
	"/profile/destinations",
	"/profile/destinations/preferred",
	"/profile/home",
	"/profile/host",
	"/profile/language",
	"/profile/notifications",
	"/profile/programme",
	"/services",
	"/share"
].concat(accommodationStaticRoutes);

const knownDynamicRoutes = /^\/notification\/[1-9][0-9]*$/;

const isNodeUrl = url => !knownStaticRoutes.includes(url) && url.match(knownDynamicRoutes) === null;

const getNotificationIdFromUrl = url => {
	const match = url.match(/^\/notification\/([1-9][0-9]*)$/);
	return match ? Number(match[1]) : undefined;
};

const views = ["stream", "map", "liked", "liked-map", "my-content", "my-content-map", "search"];

export class RoutedTranslatedApp extends PureComponent {
	state = {
		loadedPolyglotLocale: undefined
	};

	/**
	 * Generates and stores a t() function which we can pass into components.
	 * Internally it just pulls t out of polyglot and binds it to polyglot so
	 * it can be passed around. Otherwise we'd have to pass polyglot itself
	 * and client code should access it via polyglot.t().
	 */
	generateAndStoreTranslationFunctions = (locale, phrases) => {
		const polyglot = new Polyglot({ locale, phrases });
		this.t = polyglot.t.bind(polyglot);
		this.t.map = tMap(this.t);
	};

	componentDidMount() {
		// ERA-219 Perform a simple, purposeless ping to the server so the capabilities of the client are logged.
		window.setTimeout(pingServer, pingServerDelay);
		// ssr.php may have already provided the data we need.
		if (window.SERVER_DATA && window.SERVER_DATA.i18n) {
			this.generateAndStoreTranslationFunctions(window.SERVER_DATA.i18n.locale, window.SERVER_DATA.i18n);
			this.setState({ loadedPolyglotLocale: window.SERVER_DATA.i18n.locale });
			delete window.SERVER_DATA.i18n;
			return;
		}
		this.loadUiLocale(DEFAULT_LANGUAGE);
	}

	loadUiLocale = locale => {
		const { loadedPolyglotLocale } = this.state;
		if (loadedPolyglotLocale === locale) return;
		console.log(`Current UI locale is ${loadedPolyglotLocale}. Loading ${locale}.`);
		import(`../i18n.${locale}.js`)
			.then(i18n => {
				this.generateAndStoreTranslationFunctions(locale, i18n.default);
				this.setState({ loadedPolyglotLocale: locale });
				window.document.documentElement.setAttribute("lang", locale);
			})
			.catch(error => {
				console.log(error);
				// window.alert("Translation file not found. Please try again.");
			});
	};

	render() {
		return !this.state.loadedPolyglotLocale ? (
			<Loading />
		) : (
			<Router history={history}>
				<Route
					path=":url(/.*)"
					render={props => (
						<App
							url={props.match.params.url}
							search={props.location.search ? props.location.search.replace(/^\?/, "") : undefined}
							t={this.t}
							locale={this.state.loadedPolyglotLocale}
							loadUiLocale={this.loadUiLocale}
						/>
					)}
				/>
			</Router>
		);
	}
}

/**
 * Notify the React Native wrapper that the user visited /login ERA-398.
 */
export const notifyReactNativeThatLoginModalOpened = () => {
	if (!window.ReactNativeWebView) return;
	window.ReactNativeWebView.postMessage("loginModalOpened");
};

export default class App extends PureComponent {
	state = {
		user: undefined, // Will eventually be an object or null (for anonymous).
		languages: undefined,
		journeyMenu: undefined, // Will eventually be an object or null (if a journey menu is not found).
		stream: undefined,
		menus: undefined,
		terms: undefined,
		node: undefined,
		nodeLoadingStatus: undefined, // ['loading', 'error-404', 'error-5xx', 'error-network']
		notification: undefined,
		notificationLoadingStatus: undefined, // ['loading', 'error-404', 'error-5xx', 'error-network']
		showOnboarding: false
	};

	static propTypes = {
		url: PropTypes.string.isRequired,
		search: PropTypes.string,
		t: PropTypes.func.isRequired,
		locale: PropTypes.string.isRequired,
		loadUiLocale: PropTypes.func.isRequired
	};

	componentDidMount() {
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.openAllExternalLinksWithNativeBrowser();
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.notifyReactNativeThatAppMounted();
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.sendCurrentURLToReactNative();
		if (RUNS_THROUGH_REACT_NATIVE_APP) this.listenForMessagesFromReactNative();
		if (!RUNS_THROUGH_REACT_NATIVE_APP) this.listenForMessagesFromServiceWorker();
		// ssr.php may have already provided the data we need.
		if (window.SERVER_DATA && window.SERVER_DATA.App) {
			this.setState({
				...window.SERVER_DATA.App,
				...this.getLocalStorageDependendInitialState()
			});
			delete window.SERVER_DATA.App;

			return;
		}
		this.loadCoreDataFromServerAndSetState();
		this.setState(this.getLocalStorageDependendInitialState());
	}

	getLocalStorageDependendInitialState = () => ({
		showOnboarding: !isOnboardingCompleted()
	});

	componentDidCatch(error, info) {
		trackError(error.message, info.componentStack);
	}

	openAllExternalLinksWithNativeBrowser() {
		window.document.addEventListener(
			"click",
			event => {
				const anchor = getClickedAnchor(event.target);
				if (!anchor) return;
				const href = anchor.getAttribute("href");
				if (!isExternalLink(href)) return;
				if (!window.ReactNativeWebView) return;
				event.stopPropagation();
				event.preventDefault();
				window.ReactNativeWebView.postMessage("openExternalLink," + href);
			},
			true
		);
	}

	notifyReactNativeThatAppMounted() {
		if (!window.ReactNativeWebView) return;
		window.ReactNativeWebView.postMessage("appMounted");
	}

	/**
	 * Notify the React Native wrapper that the user has logged out.
	 */
	notifyReactNativeThatUserLoggedOut() {
		if (!window.ReactNativeWebView) return;
		window.ReactNativeWebView.postMessage("logout");
	}

	/**
	 * Notify the React Native wrapper with the current URL ERA-232.
	 */
	sendCurrentURLToReactNative() {
		history.listen(({ pathname, search }) => {
			if (!window.ReactNativeWebView) return;
			window.ReactNativeWebView.postMessage("currentUrl," + pathname + search);
		});
	}

	/**
	 * Notify the React Native wrapper that we need the push token of the user.
	 */
	notifyReactNativeToSubscribeToPushNotifications() {
		if (!window.ReactNativeWebView) return;
		window.ReactNativeWebView.postMessage("subscribeToPushNotifications");
	}

	/**
	 * Listen for various messages from the React Native wrapper and act accordingly.
	 */
	listenForMessagesFromReactNative() {
		// Destructure "data" and rename.
		const eventHandler = ({ data: rawData }) => {
			console.log("Received message from React Native: " + rawData);
			let data = {};
			try {
				data = JSON.parse(rawData);
			} catch (_error) {
				throw new Error("Malformed message from React Native: " + rawData);
			}
			// ERA-214 deep linking
			if (data.command === "navigateTo" && data.url) {
				history.push(data.url);
			}
			// ERA-232 back button press
			if (data.command === "navigateBack") {
				history.goBack();
			}
			// ERA-144 push subscription
			if (data.command === "pushSubscription" && data.os && data.token) {
				postPushSubscription({ type: data.os, token: data.token });
			}
			// ERA-144 push notification
			if (data.command === "pushNotification") {
				this.reloadUser();
			}
		};
		// postMessage difference between platforms: https://github.com/react-native-webview/react-native-webview/issues/356#issuecomment-467430141
		// iOS
		window.addEventListener("message", eventHandler);
		// Android
		window.document.addEventListener("message", eventHandler);
	}

	/**
	 * Listen for various messages from the service worker and act accordingly.
	 */
	listenForMessagesFromServiceWorker() {
		if (!window.navigator.serviceWorker) return;
		window.navigator.serviceWorker.addEventListener("message", ({ data }) => {
			console.log("Received message from service worker: " + data);
			// ERA-144 push notification
			if (data === "pushNotification") {
				this.reloadUser();
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.getUrlPhaseId(prevProps) !== this.getUrlPhaseId(this.props)) {
			console.log("Phase id changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (this.getUrlView(prevProps) !== this.getUrlView(this.props)) {
			console.log("View changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (this.getUrlCityId(prevProps) !== this.getUrlCityId(this.props)) {
			console.log("City id changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (this.getUrlContentTypes(prevProps) !== this.getUrlContentTypes(this.props)) {
			console.log("CTs changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (this.getUrlTags(prevProps) !== this.getUrlTags(this.props)) {
			console.log("Tags changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (this.getUrlSearch(prevProps) !== this.getUrlSearch(this.props)) {
			console.log("Search changed. Loading stream data from server.");
			this.loadCoreDataFromServerAndSetState(["stream"]);
			return;
		}
		if (!prevState.user && this.state.user) {
			console.log("User logged in. Ask for push subscription and send it to server.");
			if (!RUNS_THROUGH_REACT_NATIVE_APP) subscribeToPushNotifications();
			if (RUNS_THROUGH_REACT_NATIVE_APP) this.notifyReactNativeToSubscribeToPushNotifications();
		}
	}

	/**
	 * Loads core data from server such as user, languages, journeyMenu, stream,
	 * menus. These usually never change during the front-end lifetime (unless
	 * an action mutates them).
	 *
	 * On production this is only executed when the user is changing locale.
	 */
	loadCoreDataFromServerAndSetState(
		assets = ["user", "languages", "journeyMenu", "stream", "menus", "terms"],
		onSuccess
	) {
		const { t } = this.props;

		// Keep track of a global incremental request id so we can ignore out of
		// date (stale) responses.
		const requestId = (this.lastRequestId = (this.lastRequestId || 0) + 1);
		axios
			.all([
				assets.includes("user") &&
					loadUser().then(user => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ user: user });
						this.props.loadUiLocale(user ? user.language : DEFAULT_LANGUAGE);
					}),
				assets.includes("languages") &&
					loadLanguages().then(languages => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ languages: languages });
					}),
				assets.includes("journeyMenu") &&
					loadJourneyMenu().then(journeyMenu => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ journeyMenu: journeyMenu });
					}),
				assets.includes("stream") &&
					loadStream(
						this.getUrlPhaseId(),
						this.getUrlView(),
						this.getUrlCityId(),
						this.getUrlContentTypes(),
						this.getUrlTags(),
						this.getUrlSearch()
					).then(stream => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ stream: stream });
					}),
				assets.includes("menus") &&
					loadMenus().then(menus => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ menus: menus });
					}),
				assets.includes("terms") &&
					loadTerms().then(terms => {
						if (requestId !== this.lastRequestId) return; // Ignore stale response.
						this.setState({ terms: terms });
					})
			])
			.then(() => {
				if (onSuccess) onSuccess();
			})
			// One (or more) of the server requests failed.
			.catch(error => handleError(t, error));
	}

	onNodeLikesChange = nodeSubsetWithUpdatedLikes => {
		const { stream } = this.state;
		this.setState({
			stream: ["map", "liked-map", "my-content-map"].includes(this.getUrlView()) // Do not update node in stream because user is in map mode.
				? stream
				: {
						...stream,
						nodes: stream.nodes.map(node =>
							node.id === nodeSubsetWithUpdatedLikes.id
								? { ...node, ...nodeSubsetWithUpdatedLikes }
								: node
						)
					}
		});
	};

	onNodeVisitedChange = () => this.loadCoreDataFromServerAndSetState(["journeyMenu"]);

	onNodeDeleted = () => this.loadCoreDataFromServerAndSetState(["user", "stream"]);

	reloadUser = onSuccess => this.loadCoreDataFromServerAndSetState(["user", "stream"], onSuccess);

	/**
	 * Determines whether core state has been loaded from the server.
	 */
	isCoreStateLoaded = state =>
		state.user !== undefined &&
		state.languages !== undefined &&
		state.journeyMenu !== undefined &&
		state.stream !== undefined &&
		state.menus !== undefined &&
		state.terms !== undefined;

	logout = () => {
		const { t } = this.props;
		logoutFromDrupal()
			.then(() => {
				// This will reload core data (including the non logged in user),
				// which will load the default UI locale.
				this.loadCoreDataFromServerAndSetState();
				if (RUNS_THROUGH_REACT_NATIVE_APP) this.notifyReactNativeThatUserLoggedOut();
				if (!RUNS_THROUGH_REACT_NATIVE_APP) unsubscribeFromPushNotifications();
			})
			.catch(error => handleError(t, error));
	};

	setUserLanguage = language => {
		const { t, locale } = this.props;
		if (!language || locale === language) return;
		console.log(`Language changed to ${language}. Updating profile and reloading core data from server.`);
		updateUserLanguage(language)
			.then(() => {
				// This will reload the user, which will bring in the new locale,
				// which will in turn load that UI locale.
				this.loadCoreDataFromServerAndSetState(
					["user", "languages", "journeyMenu", "stream", "menus", "terms"],
					() => history.push(this.getUrl("/profile"))
				);
			})
			.catch(error => handleError(t, error));
	};

	setUserProfileFieldValue = (field, value, onSuccess) => {
		const fieldOptions = {
			homeOrganisation: {
				update: updateUserHomeOrganisation,
				remove: removeUserHomeOrganisation,
				reloadAssets: ["user", "stream"],
				returnPath: "/profile"
			},
			hostOrganisation: {
				update: updateUserHostOrganisation,
				remove: removeUserHostOrganisation,
				reloadAssets: ["user", "stream"],
				returnPath: "/profile"
			},
			programme: {
				update: updateUserProgramme,
				remove: removeUserProgramme,
				reloadAssets: ["user", "journeyMenu", "stream"],
				returnPath: "/profile"
			},
			subscribedNotificationCategories: {
				update: updateUserSubscribedNotificationCategories,
				remove: undefined, // Never called.
				reloadAssets: ["user"],
				returnPath: "/profile"
			},
			homeFaculty: {
				update: updateUserHomeFaculty,
				remove: removeUserHomeFaculty,
				reloadAssets: ["user"],
				returnPath: "/profile/academic"
			},
			ectsCreditsCompleted: {
				update: updateUserEctsCreditsCompleted,
				remove: removeUserEctsCreditsCompleted,
				reloadAssets: ["user"],
				returnPath: "/profile/academic"
			},
			studyLevel: {
				update: updateUserStudyLevel,
				remove: removeUserStudyLevel,
				reloadAssets: ["user"],
				returnPath: "/profile/academic"
			},
			studyField: {
				update: updateUserStudyField,
				remove: removeUserStudyField,
				reloadAssets: ["user"],
				returnPath: "/profile/academic"
			},
			languageSkills: {
				update: updateUserLanguageSkills,
				remove: undefined, // Never called.
				reloadAssets: ["user"],
				returnPath: "/profile/academic/language-skills"
			},
			preferredHostFaculties: {
				update: updateUserPreferredHostFaculties,
				remove: undefined, // Never called.
				reloadAssets: ["user"],
				returnPath: "/profile/destinations/preferred"
			}
		}[field];
		if (!fieldOptions) return;
		(value === undefined ? fieldOptions.remove() : fieldOptions.update(value))
			.then(() => {
				this.loadCoreDataFromServerAndSetState(fieldOptions.reloadAssets, () => {
					const newUrl = this.getUrl(fieldOptions.returnPath);
					// Do not push new URL to history if it's the same to current URL.
					if (this.props.url !== newUrl) {
						history.push(newUrl);
					}
					if (onSuccess) onSuccess();
				});
			})
			.catch(error => handleError(this.props.t, error));
	};

	moveUserToNextMobilityPhase = () => {
		const { t } = this.props;
		const { user } = this.state;
		moveUserToNextMobilityPhase(user.mobilityPhase.id)
			.then(() => {
				this.loadCoreDataFromServerAndSetState(["user", "stream"], () =>
					history.push(this.getUrl("/", { phase: undefined }))
				);
			})
			.catch(error => handleError(t, error));
	};

	loadMoreStreamNodes = () => {
		const { t } = this.props;
		const { stream } = this.state;
		this.setState({ stream: { ...stream, loadingStatus: "loading" } });
		loadNextStreamNodes(stream)
			.then(stream => this.setState({ stream: { ...stream, loadingStatus: undefined } }))
			.catch(error =>
				handleError(
					t,
					error,
					undefined, // 401
					undefined, // 404
					() => this.setState({ stream: { ...stream, loadingStatus: "error-5xx" } }),
					() => this.setState({ stream: { ...stream, loadingStatus: "error-network" } })
				)
			);
	};

	closeLoadMoreStreamNodes = () => {
		const { stream } = this.state;
		this.setState({ stream: { ...stream, loadingStatus: undefined } });
	};

	getActivePhase = () => {
		const { user, journeyMenu } = this.state;

		if (!journeyMenu) return undefined; // This could happen on a fresh drupal installation.

		const userPhase = user && user.mobilityPhase ? user.mobilityPhase : undefined;
		const urlPhaseId = this.getUrlPhaseId();
		const firstJourneyMenuPhase = journeyMenu.submenus[0] ? journeyMenu.submenus[0].phase : undefined;

		if (urlPhaseId) {
			const submenu = journeyMenu.submenus.find(submenu => submenu.phase.id === urlPhaseId);
			if (submenu) return submenu.phase;
		}
		if (userPhase && journeyMenu.submenus.find(submenu => submenu.phase.id === userPhase.id)) return userPhase;

		return firstJourneyMenuPhase;
	};

	getActiveSubmenu = () => {
		const { journeyMenu } = this.state;
		const activePhase = this.getActivePhase();
		if (!activePhase) return undefined;
		return journeyMenu.submenus.find(submenu => submenu.phase.id === activePhase.id);
	};

	getUrlPhaseId = (props = this.props) => Number(decodeQuery(props.search).phase) || undefined;

	getUrlView = (props = this.props) => {
		const view = decodeQuery(props.search).view;
		return views.includes(view) ? view : views[0];
	};

	getUrlCityId = (props = this.props) => Number(decodeQuery(props.search).city) || undefined;

	getUrlContentTypes = (props = this.props) => decodeQuery(props.search).cts || undefined;

	getUrlTags = (props = this.props) => decodeQuery(props.search).tags || undefined;

	getUrlSearch = (props = this.props) => decodeQuery(props.search).q || undefined;

	getUrl = (path = window.location.pathname, extraParams) => {
		const url = new URL(path, "file:"); // Use a dummy origin because we only care about root relative URLs.
		const phaseId = this.getUrlPhaseId();
		if (phaseId) {
			url.searchParams.set("phase", phaseId);
		}
		const view = this.getUrlView();
		if (view !== views[0]) {
			url.searchParams.set("view", view);
		}
		const cityId = this.getUrlCityId();
		if (cityId) {
			url.searchParams.set("city", cityId);
		}
		const contentTypes = this.getUrlContentTypes();
		if (contentTypes) {
			url.searchParams.set("cts", contentTypes);
		}
		const tags = this.getUrlTags();
		if (tags) {
			url.searchParams.set("tags", tags);
		}
		const search = this.getUrlSearch();
		if (search) {
			url.searchParams.set("q", search);
		}
		if (extraParams) {
			Object.entries(extraParams).map(([key, value]) =>
				value === undefined ? url.searchParams.delete(key) : url.searchParams.set(key, value)
			);
		}
		url.searchParams.sort();
		return url.pathname + url.search;
	};

	/**
	 * For child component to safely update part of the state. Also resets any other loader status to prevent their
	 * possible errors from staying up on screen.
	 */
	setNodeState = state =>
		this.setState({
			...filterObject(state, ["node", "nodeLoadingStatus"]),
			notificationLoadingStatus: undefined
		});

	/**
	 * For child component to safely update part of the state. Also resets any other loader status to prevent their
	 * possible errors from staying up on screen.
	 */
	setNotificationState = state =>
		this.setState({
			...filterObject(state, ["notification", "notificationLoadingStatus"]),
			nodeLoadingStatus: undefined
		});

	setOnboardingCompleted = () => {
		setOnboardingCompleted();
		this.setState({ showOnboarding: false });
	};

	render() {
		const { user, languages, journeyMenu, stream, menus, terms, showOnboarding } = this.state;
		const { url, search, t, locale } = this.props;

		// Do not render application until core state has loaded.
		// We need this so we can ensure the body of the application will do have
		// those to work with.
		// This part of the process is only applicable while development, because
		// ssr.php already provides these on initial page view in window.SERVER_DATA.
		if (!this.isCoreStateLoaded(this.state)) {
			return <Loading t={t} />;
		}

		const activePhase = this.getActivePhase();
		const activeSubmenu = this.getActiveSubmenu();
		const view = this.getUrlView();

		const visibleSlidingPanel = url !== "/" && !accommodationStaticRoutes.includes(url);
		const notificationId = getNotificationIdFromUrl(url);

		if (!visibleSlidingPanel) setTitle([t("title")]);

		const hiddenJourneyNavigatorTimeline = [
			"liked",
			"liked-map",
			"my-content",
			"my-content-map",
			"search"
		].includes(view);
		const currentSubmenu = activePhase
			? journeyMenu.submenus.find(submenu => submenu.phase.id === activePhase.id)
			: undefined;

		const journeyNavigatorTitle =
			view === "liked" ? (
				<Fragment>{t("AppHeader.liked")}</Fragment>
			) : view === "liked-map" ? (
				<Fragment>{t("AppHeader.liked")}</Fragment>
			) : view === "my-content" ? (
				<Fragment>{t("AppHeader.my-content")}</Fragment>
			) : view === "my-content-map" ? (
				<Fragment>{t("AppHeader.my-content")}</Fragment>
			) : view === "search" ? (
				<Fragment>{t("AppHeader.search")}</Fragment>
			) : (
				currentSubmenu && (
					<Fragment>
						<span>{t("JourneyNavigator.title")}</span>
						{currentSubmenu.title}
					</Fragment>
				)
			);
		const inAccommodation = isAccommodationUrl(url);
		const hasJourneyNavigator = !inAccommodation;
		const hasStream = !inAccommodation;
		return (
			<div className={`App${view ? " view-" + view : ""}${visibleSlidingPanel ? " visible-modal" : ""}`}>
				<header>
					<AppHeader
						t={t}
						getUrl={this.getUrl}
						search={search}
						user={user}
						menus={menus}
						url={url}
						view={view}
					/>
					{hasJourneyNavigator && (
						<JourneyNavigator
							t={t}
							getUrl={this.getUrl}
							search={search}
							user={user}
							journeyMenu={journeyMenu}
							activePhase={activePhase}
							view={view}
							filtersEffective={
								this.getUrlCityId() > 0 ||
								this.getUrlContentTypes() !== undefined ||
								this.getUrlTags() !== undefined
							}
							hiddenTimeline={hiddenJourneyNavigatorTimeline}
							title={journeyNavigatorTitle}
						/>
					)}
				</header>
				<main role="main">
					{hasStream && ["stream", "liked", "my-content", "search"].includes(view) ? (
						["stream", "liked", "my-content", "search"].includes(stream.type) ? (
							<Stream
								t={t}
								getUrl={this.getUrl}
								user={user}
								stream={stream}
								loadMore={this.loadMoreStreamNodes}
								closeLoadMore={this.closeLoadMoreStreamNodes}
								onNodeLikesChange={this.onNodeLikesChange}
								notificationCategories={terms.notificationCategories}
							/>
						) : (
							<Loading t={t} />
						)
					) : undefined}
					{hasStream && ["map", "liked-map", "my-content-map"].includes(view) ? (
						["map", "liked-map", "my-content-map"].includes(stream.type) ? (
							<Map
								t={t}
								getUrl={this.getUrl}
								user={user}
								nodes={stream.nodes}
								limitMinZoom={["map"].includes(view)}
							/>
						) : (
							<Loading t={t} />
						)
					) : undefined}
					{inAccommodation && (
						<Accommodation t={t} getUrl={this.getUrl} user={user} url={url} search={search} />
					)}
					<LoginPanel t={t} open={url === "/login"} close={this.getUrl("/")} user={user} />
					<ProfilePanel
						t={t}
						getUrl={this.getUrl}
						open={!isNodeUrl(url) && url.startsWith("/profile")}
						close={this.getUrl("/")}
						url={url}
						user={user}
						logout={this.logout}
						localeSwitcher={
							<LocaleSwitcher
								t={t}
								locale={locale}
								setLocale={this.setUserLanguage}
								languages={languages}
							/>
						}
						programmes={terms.programmes}
						languages={languages}
						reloadUser={this.reloadUser}
						notificationCategories={terms.notificationCategories}
						setUserProfileFieldValue={this.setUserProfileFieldValue}
						studyLevels={terms.studyLevels}
						worldLanguages={terms.worldLanguages}
						languageLevels={terms.languageLevels}
						academicYears={terms.academicYears}
						academicTerms={terms.academicTerms}
					/>
					<SlidingPanel
						t={t}
						isVisible={user !== undefined && url === "/applications"}
						title={t("Applications.title")}
						extraCssClass="ApplicationsPanel"
						close={this.getUrl("/")}
						back={true}
					>
						<Applications t={t} getUrl={this.getUrl} user={user} reloadUser={this.reloadUser} />
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/about"}
						title={t("MenuPanel.about")}
						extraCssClass="MenuPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<Menu menu={menus["erasmus-about"]} getUrl={this.getUrl} />
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/notifications"}
						title={t("AppHeader.notifications")}
						extraCssClass="NotificationsPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<Notifications
							t={t}
							getUrl={this.getUrl}
							notifications={user ? user.notifications : undefined}
						/>
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/services"}
						title={t("MenuPanel.services")}
						extraCssClass="MenuPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<Menu menu={menus["erasmus-services"]} getUrl={this.getUrl} />
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/journey"}
						title={journeyMenu.title}
						extraCssClass="JourneyMenuPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<JourneyMenu t={t} getUrl={this.getUrl} journeyMenu={journeyMenu} />
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/checklist"}
						title={activeSubmenu ? activeSubmenu.title : undefined}
						extraCssClass="JourneyChecklistPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<JourneyChecklist
							t={t}
							getUrl={this.getUrl}
							journeyMenu={journeyMenu}
							phase={activePhase}
							user={user}
							moveUserToNextMobilityPhase={this.moveUserToNextMobilityPhase}
						/>
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={url === "/share"}
						title={t("ShareOptions.title")}
						extraCssClass="SharePanel"
						close={this.getUrl("/")}
						back={false}
					>
						<ShareOptions t={t} getUrl={this.getUrl} />
					</SlidingPanel>
					<SlidingPanel
						t={t}
						isVisible={["/filters", "/filters/city", "/filters/content", "/filters/tags"].includes(url)}
						title={t("Filters.title")}
						extraCssClass="FiltersPanel"
						close={this.getUrl("/")}
						back={false}
					>
						<Filters
							t={t}
							getUrl={this.getUrl}
							url={url}
							cityFilter={stream.filters.city}
							contentTypesSearchParam={this.getUrlContentTypes()}
							filterContentTypes={terms.filterContentTypes}
							tagsSearchParam={this.getUrlTags()}
							tags={terms.tags}
							resultsSize={stream.ids ? stream.ids.length : stream.nodes.length}
						/>
					</SlidingPanel>
					<NodeLoader
						t={t}
						getUrl={this.getUrl}
						user={user}
						url={url}
						search={search}
						shouldLoadNode={isNodeUrl(url)}
						onNodeVisitedChange={this.onNodeVisitedChange}
						onNodeLikesChange={this.onNodeLikesChange}
						setParentState={this.setNodeState}
						node={this.state.node}
						nodeLoadingStatus={this.state.nodeLoadingStatus}
						onNodeDeleted={this.onNodeDeleted}
						close={this.getUrl(!inAccommodation ? "/" : "/accommodation")}
					/>
					<NotificationLoader
						t={t}
						getUrl={this.getUrl}
						user={user}
						notificationId={notificationId}
						onNotificationReadChange={this.reloadUser}
						setParentState={this.setNotificationState}
						notification={this.state.notification}
						notificationLoadingStatus={this.state.notificationLoadingStatus}
					/>
					{/* Onboarding last so it appears on top of everything else. */}
					<SlidingPanel
						t={t}
						isVisible={showOnboarding}
						title={t("Onboarding.title")}
						close={this.setOnboardingCompleted}
						extraCssClass="OnboardingPanel"
						back={false}
						backdrop={true}
					>
						<Onboarding
							t={t}
							getUrl={this.getUrl}
							programmes={terms.programmes}
							setOnboardingCompleted={this.setOnboardingCompleted}
						/>
					</SlidingPanel>
				</main>
			</div>
		);
	}
}
